<template>
<section class="content">
  <div class="card">
    <div class="card-header">
          <div class="box-tools card-tools toolbar">
            <router-link to="/info/create" class="btn btn-primary btn-flat"
              ><i class="fa fa-plus"></i> Tambah Info
            </router-link>
          </div>
    </div>
    <div class="card-body">
      <table class="table table-hover" ref="tblinfo">
            <thead>
              <tr>
                <th>JUDUL</th>
                <th>KODE</th>
                <th>TINDAKAN</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
      </table>
    </div>
  </div>
</section>
</template>

<script>
import { authFetch, createTable } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
// import { VueEditor } from "vue2-editor";

export default {
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log('load initial data', this.$route);
  },
  components: { 
    // VueEditor,
    // vSelect, 
  },
  data: () => ({
    optMapel: [],
    roles: '',
    method: "POST",
  }),
  methods: {
    handleClick(e) {
      // const evt = this.$refs;
      if (e.target.closest("button")) {
        let id = e.target.dataset.id;
          if (e.target.dataset.action == "view") {
              this.$router.push("/info/" + e.target.dataset.id);
          } else if (e.target.dataset.action == "edit") {
            this.$router.push({ path: `/info/edit/${id}`});
          } else if (e.target.dataset.action == "hapus") {
              Swal.fire({
              title: "Hapus info?",
              icon: "question",
              denyButtonText: '<i class="fa fa-times"></i> Hapus',
              showCancelButton: true,
              showDenyButton: true,
              showConfirmButton: false,
            }).then((result) => {
              if (result.isDenied) {
                authFetch("/info/" + id, {
                  method: "DELETE",
                  body: "id=" + id,
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((js) => {
                    this.table.api().ajax.reload();
                  });
              }
            });
          }
      }
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblinfo, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/info",
      frame: false,
      filter: true,
      selectedRowClass: "",
      serverSide: true,
      columns: [
        { data: "title" },
        { data: "code" },
        { data: "action",
          sortable: false,
          render: function (data, type, row, meta) {
              let dropdown =
            `<div class="btn-group">
            <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Klik
            </button>
            <div class="dropdown-menu dropdown-menu-right">
            <button type="button" class="btn btn-sm btn-info dropdown-item" data-action="view" data-id="` +
                    row.code +
                    `"> Preview </button>
            <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
                    row.id +
                    `"> Edit </button>
            <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="hapus" data-id="` +
                    row.id +
                    `">Hapus</button>
            </div>
            </div>`;

            return dropdown;
          }, },
      ],
      filterBy: [0],
      rowCallback: function (row, data) {},
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>